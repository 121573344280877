import React from "react";
import { Container, Box, Center, Text, Spacer } from "@chakra-ui/react";

interface Props {
  title: string;
  content: string;
  image: string;
  imageLink: string;
}

const LinkCta: React.FC<Props> = (props) => {
  return (
    <div className='linkCta'>
      <Center>
        <Box
          margin='10'
          maxW='xlg'
          borderWidth='1px'
          borderRadius='lg'
          overflow='hidden'
        >
          <Container>
            <a href={props.imageLink}>
              <Box padding={5}>
                <Text bg={"blue.500"} color={"white"}>
                  {props.title}
                </Text>
                <small>{props.content}</small>
                <Spacer marginBottom={5} />

                <img src={props.image} alt={props.title + " image"} />
              </Box>
            </a>
          </Container>
        </Box>
      </Center>
    </div>
  );
};

export default LinkCta;
