import Header from "../Components/Header";
import LinkCta from "../Components/LinkCta";
import Fly from "../Components/Butterfly.js";
import { Box, Spacer, Text } from "@chakra-ui/react";
import tfo from "../Images/Transfer-Files-Online-P2P.png";
import rug from "../Images/rug.png";
import pathGen from "../Images/pathgen.png";
import jR from "../Images/jsons.png";
import underConst from "../Images/led-scroller.png"
import podo from "../Images/podomoro.png"

function Home() {
  return (
    <Box id='container' className='home'>
      <Header></Header>
      
      <Box width={50} height={50}>
        <Fly />
      </Box>
      <Box>
        <Text>Welcome to JarrardXyz,</Text>
        <Text>An incomplete portfolio of some of my side projects</Text>
        <Text>
          I'm a big believer in open source, so many of my apps are free.
        </Text>
        <small>Not that anyone would pay for them</small>
      </Box>
      <LinkCta

        title='Podomoro Timer With Task List'
        content='A tool for practicing the Podomoro technique'
        image={podo}
        imageLink='https://jjarrard.github.io/podomoro/'
      ></LinkCta>

      <LinkCta
        title='Free Under Contruction Page'
        content='An LED scroller looking holding page'
        image={underConst}
        imageLink='https://jjarrard.github.io/under-construction-LED-Scroller/'
      ></LinkCta>

      <LinkCta
        title='XYZ Tool Path Generator'
        content='A tool to create basic CNC paths'
        image={pathGen}
        imageLink='https://developersweet.com/'
      ></LinkCta>

      <LinkCta
        title='Transfer Files Online [Retired]'
        content='Encrypted P2P File Transfer Tool'
        image={tfo}
        imageLink='https://jarrard.xyz'
      ></LinkCta>

      <LinkCta
        title='Random User Generator'
        content='A Chrome extension for creating test JSON user objects'
        image={rug}
        imageLink='https://chrome.google.com/webstore/detail/random-user-generator/gifefcjlladpifaohabngaoadodhkjlg'
      ></LinkCta>
      
      <LinkCta
        title='JSON Replacer'
        content='A Chrome extension for finding and replacing keys or values in a JSON array or object'
        image={jR}
        imageLink='https://chrome.google.com/webstore/detail/json-replacer/egifieelfbfkjhfhbadkkkpimdiebehg'
      ></LinkCta>
      
    </Box>
  );
}

export default Home;
