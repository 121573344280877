import React from "react";
import { Box, Text, Image, Link } from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";
import logo from "../Images/logo.png";

const Header = () => {
  const headerPadding = "0 20px"; // Adjust the padding as needed

  const boxShadow = "0px 4px 6px rgba(0, 0, 0, 0.1)"; // Adjust shadow parameters as needed

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding={headerPadding}
      boxShadow={boxShadow} // Apply box shadow here
    >
      <Image src={logo} alt="Logo" width="150px" height="auto" />

      <Link href="mailto:contact@jarrard.xyz">
        <Box
          display="flex"
          alignItems="center"
          padding={headerPadding}
        >
          <EmailIcon
            boxSize={{ base: 6, md: 8 }} // Adjust icon size for responsiveness
            color="blue.500"
            marginRight={2}
            _hover={{ textDecoration: "underline" }}
          />
          <Text
            fontWeight="bold"
            display={{ base: "none", md: "inline" }} // Hide on mobile, show on larger screens
          >
            contact@jarrard.xyz
          </Text>
        </Box>
      </Link>
    </Box>
  );
};

export default Header;
