import fly from "../Images/fly.jpg";
var hpos = 0;
var wpos = 0;

var moving = 0;

function Fly() {
  var butt = document.getElementById("butterfly");
  var container = document.getElementById("container");

  if (butt && container) {
    if (moving != 1) {
      moving = 1;
      var contWidth = container.clientWidth - 100;
      var contHeight = container.clientHeight;

      var randomHeight = Math.floor(Math.random() * contHeight);
      var randomWidth = Math.floor(Math.random() * contWidth);
      var rotation = 0;
      var speed = 20;
      var id = setInterval(frame, 10);
      function frame() {
        if (
          hpos > randomHeight - speed &&
          wpos > randomWidth - speed &&
          hpos < randomHeight + speed &&
          wpos < randomWidth + speed
        ) {
          clearInterval(id);
          moving = 0;
          butt.src = fly;

          if (rotation == 1) {
            butt.style.transform = "rotateZ(30deg)";
          } else {
            butt.style.transform = "rotateZ(-30deg)";
          }
        } else {
          butt.src = fly;

          //go up
          if (hpos > randomHeight) {
            hpos -= speed;
            rotation = 1;
            butt.style.top = hpos + "px";
          }

          //go down
          if (hpos < randomHeight) {
            hpos += speed;
            rotation = 2;
            butt.style.top = hpos + "px";
            butt.style.transform = "rotateZ(0deg)";
          }

          //go left
          if (wpos > randomWidth) {
            wpos -= speed;
            rotation = 1;
            butt.style.left = wpos + "px";
            butt.style.transform = "rotateZ(-90deg)";
          }

          //go right
          if (wpos < randomWidth) {
            wpos += speed;
            rotation = 2;
            butt.style.left = wpos + "px";
            butt.style.transform = "rotateZ(90deg)";
          }
        }
      }
    }
  }

  return (
    <div>
      <img id='butterfly' onMouseOver={Fly} src={fly} />
    </div>
  );
}
export default Fly;
